import React from "react"
// import { Link } from "gatsby"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import T_FORMPAGE_BG from "../../../../../components/t-formpage_bg"
import T_SATISFACTION_FORMS from "../../../../../components/satisfaction/t-satisfaction_forms"
const HotaruSatisfactionPage = () => (
  <Layout sectionClasses={"t-satisfaction t-formpage js-satisfaction"} dataNamespace={"satisfaction"} isShowFooter={false}>
    <SEO title="SATISFACTION" />
    <T_FORMPAGE_BG />
    <T_SATISFACTION_FORMS />
  </Layout>
)

export default HotaruSatisfactionPage
