import React from "react";

import satisfaction_forms from '../../pug/_object/_project/_satisfaction/_t-satisfaction__forms.pug';

class T_Satisfaction_Forms extends React.Component {
    render() {
        return satisfaction_forms.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Satisfaction_Forms