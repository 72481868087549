import React from 'react';

export default function() {
  return (
    <div className="t-satisfaction__forms t-formpage__forms">
      <div className="p-questionsNumber js-questionsNumber">
        <div className="p-questionsNumber__current js-questionsNumber__current"></div>
        <ul className="p-questionsNumber__dots js-questionsNumber__dots"></ul>
        <div className="p-questionsNumber__total js-questionsNumber__total"></div>
      </div>
      <h2 className="t-formpage__ttl">SATISFACTION</h2>
      <form className="p-form" action="" method="post">
        <ul className="js-questionsForms js-questionsSlide is-notModal">
          <li className="p-questionsBody">
            <div className="p-questionsBody__head">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col">
                    <h3 className="p-questionsBody__ttl">
                      ローションについて
                      <br />
                      全体的な満足感を5段階で
                      <br className="u-hide-pc" />
                      評価してください
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__middle">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-14 md-10">
                    <div className="c-starRating c-starRating--center is-5levels">
                      <input type="radio" name="lotion-star-rating" value="1" />
                      <i></i>
                      <input type="radio" name="lotion-star-rating" value="2" />
                      <i></i>
                      <input type="radio" name="lotion-star-rating" value="3" />
                      <i></i>
                      <input type="radio" name="lotion-star-rating" value="4" />
                      <i></i>
                      <input type="radio" name="lotion-star-rating" value="5" />
                      <i></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__foot">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-7 md-3 xs-1"></div>
                  <div className="c-grid__col lg-10 md-6 xs-4">
                    <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                      <span className="p-button__label">NEXT</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-questionsBody">
            <div className="p-questionsBody__head">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col">
                    <h3 className="p-questionsBody__ttl">
                      ローションについて
                      <br />
                      お好みのテクスチャーを教えてください
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__middle">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-14 md-10">
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="lotion-fav-texture" value="もっとさらっと" />
                      <label>
                        <span>もっとさらっと</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="lotion-fav-texture" value="ちょうど良い" />
                      <label>
                        <span>ちょうど良い</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="lotion-fav-texture" value="もっととろっと" />
                      <label>
                        <span>もっととろっと</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__foot">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-5 md-4 xs-1">
                    <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                      <span className="p-button__label u-hide-sp">BACK</span>
                    </button>
                  </div>
                  <div className="c-grid__col lg-9 md-6 xs-5">
                    <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                      <span className="p-button__label">NEXT</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-questionsBody">
            <div className="p-questionsBody__head">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col">
                    <h3 className="p-questionsBody__ttl">
                      ローションをつけた直後の
                      <br />
                      保湿感について教えてください
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__middle">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-14 md-10">
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="lotion-after-feeling" value="物足りない" />
                      <label>
                        <span>物足りない</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="lotion-after-feeling" value="普通" />
                      <label>
                        <span>普通</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="lotion-after-feeling" value="十分しっとり" />
                      <label>
                        <span>十分しっとり</span>
                      </label>
                    </div>
                    <small className="p-questionsBody__caption u-block u-tac c-spacer--pt30">
                      化粧水をつけた直後に馴染みが悪い、
                      <br className="u-hide-pc" />
                      つっぱり感がある場合は
                      <br />
                      「物足りない」をご選択ください
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__foot">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-5 md-4 xs-1">
                    <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                      <span className="p-button__label u-hide-sp">BACK</span>
                    </button>
                  </div>
                  <div className="c-grid__col lg-9 md-6 xs-5">
                    <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                      <span className="p-button__label">NEXT</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-questionsBody">
            <div className="p-questionsBody__head">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col">
                    <h3 className="p-questionsBody__ttl">
                      モイスチャライザーについて
                      <br />
                      全体的な満足感を5段階で
                      <br className="u-hide-pc" />
                      評価してください
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__middle">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-14 md-10">
                    <div className="c-starRating c-starRating--center is-5levels">
                      <input type="radio" name="moisturizer-star-rating" value="1" />
                      <i></i>
                      <input type="radio" name="moisturizer-star-rating" value="2" />
                      <i></i>
                      <input type="radio" name="moisturizer-star-rating" value="3" />
                      <i></i>
                      <input type="radio" name="moisturizer-star-rating" value="4" />
                      <i></i>
                      <input type="radio" name="moisturizer-star-rating" value="5" />
                      <i></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__foot">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-5 md-4 xs-1">
                    <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                      <span className="p-button__label u-hide-sp">BACK</span>
                    </button>
                  </div>
                  <div className="c-grid__col lg-9 md-6 xs-5">
                    <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                      <span className="p-button__label">NEXT</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-questionsBody">
            <div className="p-questionsBody__head">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col">
                    <h3 className="p-questionsBody__ttl">
                      モイスチャライザーについて
                      <br />
                      お好みのテクスチャーを教えてください
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__middle">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-14 md-10">
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="moisturizer-fav-texture" value="もっとさらっと" />
                      <label>
                        <span>もっとさらっと</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="moisturizer-fav-texture" value="ちょうど良い" />
                      <label>
                        <span>ちょうど良い</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="moisturizer-fav-texture" value="もっととろっと" />
                      <label>
                        <span>もっととろっと</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__foot">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-5 md-4 xs-1">
                    <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                      <span className="p-button__label u-hide-sp">BACK</span>
                    </button>
                  </div>
                  <div className="c-grid__col lg-9 md-6 xs-5">
                    <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                      <span className="p-button__label">NEXT</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-questionsBody">
            <div className="p-questionsBody__head">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col">
                    <h3 className="p-questionsBody__ttl">
                      モイスチャライザーでケアした翌日の
                      <br />
                      肌状態について教えてください
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__middle">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-14 md-10">
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="moisturizer-after-feeling" value="日中乾燥を感じることがあった" />
                      <label>
                        <span>日中乾燥を感じることがあった</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="moisturizer-after-feeling" value="乾燥が気になることはなかった" />
                      <label>
                        <span>乾燥が気になることはなかった</span>
                      </label>
                    </div>
                    <div className="p-form__radio c-checkButton c-checkButton--text">
                      <input type="radio" name="moisturizer-after-feeling" value="保湿感を感じることができた" />
                      <label>
                        <span>保湿感を感じることができた</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-questionsBody__foot">
              <div className="c-grid">
                <div className="c-grid__row">
                  <div className="c-grid__col lg-5 md-1 xs-0"></div>
                  <div className="c-grid__col lg-5 md-4 xs-1">
                    <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                      <span className="p-button__label u-hide-sp">BACK</span>
                    </button>
                  </div>
                  <div className="c-grid__col lg-9 md-6 xs-5">
                    <button className="p-button p-button--arw-right" data-required="radio" type="submit">
                      <span className="p-button__label">COMPLETE!</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </form>
      <a className="p-pageBackBtn" href="/">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col">
              <span className="p-pageBackBtn__inner">BACK TO TOP</span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
