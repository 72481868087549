import React from "react";

import formpage_bg from '../pug/_object/_project/_t-formpage__bg.pug';

class T_Frompage_Bg extends React.Component {
    render() {
        return formpage_bg.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Frompage_Bg